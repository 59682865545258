<template>
  <div>
    <div class="content-header cols" v-if="header">
      <div>
        <h2>{{ $tf('daily | events') }}</h2>
      </div>
    </div>

    <el-row class="cols--center mar-v--1">
      <div class="cell"></div>

      <div name="table-pagination" class="table-pagination pull-right mar-v--1">
        <table-navigation :state="state"></table-navigation>
      </div>
    </el-row>

    <el-table :name="model.name + '-table'" :class="model.name + '-table'" stripe :size="$vars.sizes.table || $vars.sizes.common" :data="state.items">
      <el-table-column prop="id" :label="$tf('id')" width="60"></el-table-column>

      <el-table-column prop="thumbnail" :label="$tf('common.detected')" width="176">
        <template slot-scope="{ row }">
          <object-thumbnail :url="row.thumbnail || ''" :frame="row.fullframe || ''" @preview="(v) => showImage(row.fullframe, getBox(row))"></object-thumbnail>
        </template>
      </el-table-column>

      <el-table-column prop="match" :label="$tf('common.matched_to')" width="176">
        <template slot-scope="{ row }">
          <object-thumbnail :id="row.face_object_id" @preview="(v) => showImage(v && v.src, null)"></object-thumbnail>
        </template>
      </el-table-column>

      <el-table-column prop="name" :label="$tf('name')" min-width="200px">
        <template slot-scope="{ row }">
          <card-table-part-item :key="'item' + row.card" :confidence="row.confidence" :by-card-id="row.card"></card-table-part-item>
        </template>
      </el-table-column>

      <el-table-column prop="date" width="120px" :label="$tf('date')">
        <template slot-scope="{ row }">
          {{ row.external_timestamp | parseDate | formatDate }}<br />
          {{ row.external_timestamp | parseDate | formatTime }}
        </template>
      </el-table-column>

      <el-table-column prop="dates" width="120px" :label="$tf('camera')">
        <template slot-scope="{ row }">
          {{ row.camera }}
          <br />
          <span class="text-tetriary">
            {{ row.puppet_id }}
          </span>
        </template>
      </el-table-column>
    </el-table>

    <div name="table-pagination" class="table-pagination pull-right mar-v--1">
      <table-navigation :state="state"></table-navigation>
    </div>
  </div>
</template>

<script>
import TableNavigation from '@/components/tables/navigation.vue';
import ObjectThumbnail from '../../objects/thumbnail.vue';
import CardTablePartItem from '@/components/puppeteer/search-events/card.item';

export default {
  name: 'page-daily-events',
  components: { CardTablePartItem, ObjectThumbnail, TableNavigation },
  props: {
    card: {
      type: Number
    },
    header: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  data: function () {
    return {
      model: {
        name: 'daily-events',
        route: 'puppeteer/daily/events',
        permissions: {
          create: 'ffsecurity.add_search-events'
        }
      }
    };
  },
  computed: {
    state() {
      return this.$store.state.puppeteer_daily_events;
    }
  },
  mounted() {
    this.state.filter.current.card = this.card;
    this.$store.dispatch(this.state.Action.Get).catch((e) => {
      this.$notify({ duration: 0, message: this.$createElement('message-box', { props: { e } }) });
    });
  },
  methods: {
    load() {},
    rowClick(item) {
      if (this.$store.state.app.key.ctrl) return;
      this.$router.push({ path: this.getItemLink(item) });
    },
    getItemLink(item) {
      return `/${this.model.route}/` + encodeURIComponent(item.id) + '/';
    },
    showImage(url, box) {
      this.$store.dispatch('showImage', { src: url, box: box });
    },
    getBox(v) {
      return {
        x: v.bbox.left,
        y: v.bbox.top,
        w: v.bbox.right - v.bbox.left,
        h: v.bbox.bottom - v.bbox.top
      };
    }
  }
};
</script>
